<template>
  <div class="home-block">
    <div class="home">
      <!-- 头部开始 -->
      <div class="home-header">
        <img class="w100f" src="../../assets/images/img_big_bg.png" alt="" />
        <div class="bg-text">
          <div class="bg-text-left">
            <p class="font-title">传世新闻</p>
          </div>
          <div class="bg-text-right"></div>
        </div>
      </div>
      <!-- 头部结束 -->
      <!-- 中间内容开始 -->
      <div class="main-contain">
        <div class="h100"></div>
        <div class="news-sty" v-for="(item, index) in newsList.slice((pageIndex - 1) * pageSize, pageIndex * pageSize)" :key="index" @click="toDetail(item.id)">
          <img class="fl" width="55%" :src="item.url" alt="" />
          <div class="news-sty-txt">
            <div class="t-right">发布时间：{{item.date}}</div>
            <div class="news-sty-txt-title">{{item.title}}</div>
            <div class="news-sty-txt-content" v-html="item.content"></div>
          </div>
        </div>
        <el-pagination
          class="mt136 mb162"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          :page-size="5"
          layout="total, prev, pager, next, jumper"
          :total="newsList.length"
        >
        </el-pagination>
      </div>
    </div>
    <mobilePage></mobilePage>
  </div>
</template>
<script>
import mobilePage from '@/views/news/mobile/index.vue'//移动端页面
import Box from '@/components/box/box.vue'; //盒子组件
import Title from '@/components/title/title.vue'; //标题组件
export default {
  name: 'home',
  components: {
    Box,
    Title,
    mobilePage
  },
  data() {
    return {
      pageIndex:1,
      pageSize:5,
      currentIndex: 0,
      dialogVisible: false,
      newsList:[
      {
          id:9,
          title:"医药企业数智化峰会暨时空智友DHERP新产品发布会召开，传世科技发布瀑布流智能拣选设备",
          date:"2023-07-14 18:03",
          content:"<p>7月14日，由北京时空智友科技有限公司和济南时空超越科技有限公司主办的《医药企业数智化峰会暨时空智友DHERP新产品发布会》在济南正式召开。峰会围绕“数智医药时代，时空引领未来”为主题，邀请众多行业大咖做主题分享。峰会现场，时空医药企业数智化全新一代产品DHERP盛大发布。</p>",
          url:require('../../assets/images/news_block9_00.png')
        },
      {
          id:8,
          title:"2023年全球数字经济大会召开，传世科技获得北京国际大数据交易所首批数据资产登记凭证",
          date:"2023-07-06 13:11",
          content:"<p>7月4日晚，北京国家会议中心灯光璀璨，受人瞩目的2023全球数字经济大会隆重开幕。中共中央政治局委员、北京市委书记尹力，中央网信办、国家网信办主任庄荣文，国家发展和改革委员会副主任丛亮，工业和信息化部副部长王江平，中国科协专职副主席、书记处书记束为，老挝万象市市长阿沙庞通·西潘敦，阿联酋阿布扎比经济发展局副局长拉希德·布洛希，新加坡通讯及新闻部部长杨莉明（线上）、新加坡资讯通信媒体发展局局长柳俊泓，埃及最高网络安全委员会执行局主席艾哈迈德·哈菲兹，联合国教科文组织助理总干事斯蒂芬妮...</p>",
          url:require('../../assets/images/news_block8_00.png')
        },
      {
          id:7,
          title:"2023中国数字医疗大会暨中国医药教育协会数字医疗专业委员会成立大会，传世科技协办，欢迎参会！",
          date:"2023-07-05 18:12",
          content:"<p>2023首届中国数字医疗大会暨中国医药教育协会数字医疗专业委员会成立大会定于7月28日-29日在北京召开。本次会议由中国医药教育协会（CMEA）主办，中国医药教育协会数字医疗专业委员会、解放军总医院第一医学中心、清华大学附属北京清华长庚医院、北京陈菊梅公益基金会、清华大学出版社、中国信息通信研究院云计算与大数据研究所共同承办。</p>",
          
          url:require('../../assets/images/news_block7_00.png')
        },
        {
          id:0,
          title:"传世般若数字化解决方案灯塔项目：京东方健康集中采购管理系统项目",
          date:"2023-02-22 11:43",
          content:"<p>2023年，新年立春之际，京东方健康集中采购管理系统项目正式启动，京东方健康、深圳传世般若科技有限公司的双方领导及项目组成员共同出席参加启动会。</p>",
          url:require('../../assets/images/news_block6_01.png')
        },
        {
          id:1,
          title:"庆祝传世般若成立7周年！",
          date:"2022-08-08 19:35",
          content:"<p>风雨同舟，携手共进！</p><p>传世般若致力于成为医药行业数字化解决方案服务领军企业。</p>",
          url:require('../../assets/images/news_left_img05.png')
        },
        {
          id:2,
          title:"传世般若，连续入围未来医疗百强榜",
          date:"2022-06-16 22:36",
          content:"<p>由动脉网、VB100等共同推出的中国未来医疗百强评选活动，昨日发布中国创新数字医疗榜单，传世般若再次入围动脉网VB-100未来医疗百强榜。</p><p>动脉网的未来百强榜，通过考察企业在人力资源、知识资源、重要合作伙伴资源、市场表现四大一级指标，共17项二级指标数据上的成长性与年度表现做出打分，是目前生命健康领域最受产业界、资本界关注的年度评选。</p>",
          url:require('../../assets/images/news_left_img04.png')
        },
        {
          id:3,
          title:"恋链平台终端服务人员招募啦！",
          date:"2021-12-25 18:32",
          content:"<p>恋链是传世科技供应链管理和终端服务人员管理SAAS平台，具备行业领先的CRM客户关系管理、SFE销售效能管理和BI大数据分析等功能模块，助力终端服务人员开展终端服务和企业推进数字化转型。</p>",
          url:require('../../assets/images/recruitment.jpg')
        },
        { 
          id:4,
          title:"涉药运输平台西北区域数字供应链合作伙伴签约仪式在西安举行",
          date:"2021-10-12 12:12",
          content:"<p>10月25日，深圳传世般若科技有限公司（以下简称“传世”）作为涉药运输平台规则的倡议者与参与制定者参加了会议，携手京东物流、陕西天士力医药物流有限公司、陕西医药控股集团派昂医药有限责任公司共同签署了战略合作协议，共同推进涉药运输平台西北区域数字供应链和区配一体化工作，打造以陕西医药物流市场为核心区域涉药物流管控体系。</p>",
          url:require('../../assets/images/news_block3_02.jpg')
        },
        {
          id:5,
          title:"传世般若推进数字化转型：人民同泰哈药物流的选择",
          date:"2021-08-19 10:30",
          content:"<p>深圳传世般若科技有限公司（以下简称“传世科技”）创立于2015年8月，国家高新技术企业，专注于以供应链解决方案，构筑医药产业互联网，向医药企业及医院提供营销及供应链数字化的综合服务。</p><p>传世科技现已拥有医药供应链领域软件著作权30余项，各项专利20余项，为不同客户类型提供个性化产品服务，形成了完整的数字化供应链服务解决方案和产品，推动数字化医药流通变革。</p>",
          url:require('../../assets/images/news_left_img02.png')
        },
        {
          id:6,
          title:"传世商业价值研究院暨医药产业互联论坛",
          date:"2021-07-20 15:26",
          content:"<p>2021年3月10日，传世商业价值研究院启动暨医药产业互联论坛在西安交通大学曲江校区成功召开！</p><p>会议由传世般若和凯迈咨询&凯迈投资联合主办，双方共同设立传世商业价值研究院，拟通过医药政策、行业、企业研究及互联技术发展研究，找准医药产业互联发展趋势，协同传世般若为行业提供技术加持、数字赋能、产业协同、智慧升级的企业竞争力解决方案。</p>",
          url:require('../../assets/images/news_left_img01.png')
        },
        
        
        
      ]
    };
  },
  methods: {
    onTabChange(index) {
      this.currentIndex = index;
      console.log(index);
    },
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      document.body.scrollTop = document.documentElement.scrollTop = 0
    },
    toDetail(id){
      this.$router.push({
        path:'/news/detail',
        query:{
          id
        }
      })
    }
  },
};
</script>
<style lang="less" scoped>
.home {
  //头部
  .home-header {
    position: relative;
    width: 100%;
    min-height: 450px;
    .bg-text {
      width: 1200px;
      height: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      .bg-text-left {
        text-align: left;
        .font-title {
          font-size: 40px;
          margin-bottom: 20px;
          color: #ffffff;
        }
        .next-btn {
          width: 140px;
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          background: #4fa1a4;
          opacity: 1;
          border-radius: 24px;
          text-align: center;
          cursor: pointer;
        }
      }
      .bg-text-right {
        font-family: Source Han Sans CN;
        text-align: right;
        .font-one {
          font-size: 30px;
        }
        .font-two {
          font-size: 22px;
          line-height: 22px;
        }
      }
    }
  }
  //中间
  .main-contain {
    .news-sty {
      height: 495px;
      margin-bottom: 56px;
      overflow: hidden;
      cursor: pointer;
      .news-sty-txt {
        float: right;
        width: 41%;
        text-align: left;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 20px;
        color: #3d3d3d;
        .news-sty-txt-title {
          font-size: 26px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 40px;
          margin: 40px 0 40px 0;
        }
        /deep/p{
          font-size: 16px;
          line-height: 28px;
          text-indent: 2em;
        }
      }
      img{
        width: 660px;
        height: 440px;
      }
    }
  }
}
</style>
